import axios from '@/api/api-request'

const projectUrl = '/webSolution'

// 获取首页数据
export const getFirst = (data) => {
  return axios.request({
    url: projectUrl + '/getFirst.do',
    method: 'post',
    data: data
  })
}
// 解决方案管理-获取实体信息
export const getInfo = (params) => {
  return axios.request({
    url: projectUrl + '/getInfo.do',
    method: 'post',
    params: params
  })
}
// 解决方案管理-获取分页列表
export const list = (data) => {
  return axios.request({
    url: projectUrl + '/list.do',
    method: 'post',
    data: data
  })
}

// 解决方案管理-
export const getLists = (data) => {
  return axios.request({
    url: projectUrl + '/getLists.do',
    method: 'post',
    data: data
  })
}

// 解决方案管理-获取视频 压缩包文件
export const getVideoNewest = () => {
  return axios.request({
    url: projectUrl + '/getVideoNewest.do',
    method: 'get'
  })
}
export const getVideoList = (data) => {
  return axios.request({
    url: projectUrl + '/getVideoList.do',
    method: 'post',
    data: data
  })
}
// 解决方案管理-下载视频
export const downloadVideo = (params) => {
  return axios.request({
    url: projectUrl + '/downloadVideo.do',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

// 解决方案管理-获取创建时间最新的六个压缩包文件
export const getPackageNewest = () => {
  return axios.request({
    url: projectUrl + '/getPackageNewest.do',
    method: 'get'
  })
}

// 解决方案管理-下载压缩包文件
export const downloadPackage = (params) => {
  return axios.request({
    url: projectUrl + '/downloadPackage.do',
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}
