<template>
  <div class="kecheng_wk">
    <div class="px1200">
      <div class="">
        <div class="dangq_luj"><span><my-link href="/home-layout">首页</my-link> > <my-link href="/mall">培训资料</my-link></span></div>
        <div class="chanp_biapt">
          <h2>培训资料</h2>
        </div>
        <div class="class_k">
          <span>分类：</span>
          <a :class="(solutionType === '' ? 'no':'')" v-on:click="selectType('')">全部</a>
          <a v-for="type in solutionInfoType" :key="type.code" v-on:click="selectType(type.code)" :class="(solutionType === type.code ? 'no':'')">{{ type.name }}</a>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-4" v-for="solution in videodata" :key="solution.id">
            <a class="thumbnail">
              <video
                :src="httpPath + solution.imgUrl"
                class="avatar video-avatar" style="height: 250px"
                controls="controls"/>
<!--              <img class="bof_kef" src="@/assets/style/images/bofang.png" alt="">-->
              <p :title="solution.title">{{ solution.title }}</p>
              <span :title="solution.description"> {{ solution.description }}</span>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="box-all">
            <div class="col-xs-12 col-md-4 box-all-text" v-for="item in textdata" :key="item.id">
              <a class="thumbnail box-item-text">
                <!--              <img src="images/peix_img.jpg" alt="...">-->
                <!--              <img class="bof_kef" src="@/assets/style/images/bofang.png" alt="">-->
                <div class="item-text-title beyond-hide"><span :title="item.title">{{ item.title }}</span></div>
                <div class="item-text-desc"><span :title="item.description"> {{ item.description }}</span></div>
                <div class="item-text-download"><el-button type="warning" @click="dowloadPackage(httpPath + item.imgUrl)">下载</el-button></div>
              </a>
            </div>
          </div>
        </div>
        <!-- 分页组件 -->
        <pagination v-bind:child-msg="pageParam" @callFather="callFather"></pagination>
      </div>
    </div>
  </div>
<!--  <div class="wrap-lg">-->
<!--    <div class="">-->
<!--      <div class="top-title"><img src="@/assets/img/titleimg.png"><span class="title-text">培训资料</span></div>-->
<!--    </div>-->
<!--    <div class="list-box">-->
<!--      &lt;!&ndash; 培训视频&ndash;&gt;-->
<!--      <div class="box-all">-->
<!--        <div class="box-all-video">-->
<!--          <div v-for="solution in videodata" :key="solution.id" class="box-item-video">-->
<!--            <div class="item-video-img">-->
<!--              <video-->
<!--                :src="httpPath + solution.imgUrl"-->
<!--                class="avatar video-avatar"-->
<!--                controls="controls"/>-->
<!--            </div>-->
<!--            <div class="item-video-title beyond-hide">-->
<!--              <span :title="solution.title">{{ solution.title }}</span>-->
<!--            </div>-->
<!--&lt;!&ndash;            <div class="item-video-download">&ndash;&gt;-->
<!--&lt;!&ndash;              <el-button type="warning" @click="dowloadVideo(solution.imgUrl)">下载</el-button>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="list-box">-->
<!--      &lt;!&ndash; &ndash;&gt;-->
<!--      <div class="box-all">-->
<!--        <div class="box-all-text">-->
<!--          <div v-for="item in textdata" :key="item.id" class="box-item-text">-->
<!--            <div class="item-text-title beyond-hide">-->
<!--              <span :title="item.title">{{ item.title }}</span>-->
<!--            </div>-->
<!--            <div class="item-text-desc">-->
<!--              {{ item.description }}-->
<!--            </div>-->
<!--            <div class="item-text-download">-->
<!--              <el-button type="warning" @click="dowloadPackage(httpPath + item.imgUrl)">下载</el-button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import { getLists, getVideoList, getPackageNewest } from '@/api/platform/solution'
import axios from 'axios'

export default {
  name: 'index',
  data () {
    return {
      httpPath: '',
      loading: false,
      videodata: [],
      textdata: [],
      solutionType: '',
      solutionInfoType: [],
      // 分页参数
      pageParam: {
        currentPage: 1,
        pageSize: 9,
        total: 9
      }
    }
  },
  mounted () {
    this.httpPath = this.commonUtils.getPara('httpPath')
    // this.initData()
    this.solutionInfoType = this.commonUtils.getDictPara('solutionInfo_type')
    this.initVideoNewest()
    // this.initPackageNewest()
  },
  methods: {
    initPackageNewest () {
      getPackageNewest()
        .then(res => {
          var result = res.data
          if (result.rtnCode !== '0') {
            this.$message({
              type: 'info',
              message: result.msg
            })
          } else {
            this.textdata = result.bizData
          }
        }).catch(ex => {
          this.loading = false
          this.$message.error('文件列表加载失败，请稍后再试！')
        })
    },
    dowloadVideo (videoUrl) {
      // 0-0
      var fileUrl = videoUrl
      axios({
        method: 'get',
        url: fileUrl,
        responseType: 'blob' // 二进制流文件
      }).then(res => {
        const link = document.createElement('a')
        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `${name}.xlsx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).catch(req => {
      })
    },
    dowloadPackage (fileUrl) {
      window.open(fileUrl)
      let param = {}
      param.fileUrl = fileUrl
      // downloadPackage(param)
      //   .then(res => {
      //     const a = document.createElement('a')
      //     // 将会被放入到blob中的数组内容的MIME类型
      //     const blob = new Blob([res.data], {
      //       // type: 'application/vnd.ms-excel'
      //     })
      //     if (window.navigator.msSaveOrOpenBlob) {
      //       navigator.msSaveOrOpenBlob(blob, fileUrl.split('/')[fileUrl.split('/').length - 1])
      //       return
      //     }
      //     a.href = URL.createObjectURL(blob)
      //     a.download = fileUrl.split('/')[fileUrl.split('/').length - 1]
      //     a.click()
      //   })
      //   .catch(res => {
      //     this.$message.error('下载压缩包文件失败')
      //   })
    },
    initVideoNewest () {
      let param = {}
      // if (this.searchText) {
      //   param.name = this.searchText
      // }
      param.categoryCode = this.solutionType
      param.page = {
        pageNum: this.pageParam.currentPage,
        pageSize: this.pageParam.pageSize,
        orderBy: 'createTime'
      }
      this.loading = true
      getVideoList(param).then(res => {
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          this.videodata = []
          this.textdata = []
          result.bizData.forEach(item => {
            if (item.categoryCode === '1') {
              this.videodata.push(item)
            }
            if (item.categoryCode === '2') {
              this.textdata.push(item)
            }
          })
          this.pageParam.total = result.total
          // this.videodata = result.bizData
        }
      })
        .catch(ex => {
          this.loading = false
          this.$message.error('视频列表加载失败，请稍后再试！')
        })
    },
    initData () {
      let param = {}
      this.loading = true
      getLists(param).then(res => {
        this.loading = false
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          if (result.bizData && result.bizData.length > 0) {
            // this.firstSolution = result.bizData[0]
            // this.solutions = result.bizData.slice(1, 9)
            this.videodata = result.bizData.slice(0, 3)
            this.textdata = [
              {
                id: 1,
                title: '销售培训资料',
                desc: '销售流程，销售方式，如何引资'
              },
              {
                id: 2,
                title: '销售培训资料',
                desc: '销售流程，销售方式，如何引资'
              },
              {
                id: 3,
                title: '销售培训资料',
                desc: '销售流程，销售方式，如何引资'
              }
            ]
          }
        }
      }).catch(ex => {
        this.loading = false
        this.$message.error('列表加载失败，请稍后再试！')
      })
    },
    selectType (code) {
      this.solutionType = code
      this.initVideoNewest()
    },
    callFather (parm) {
      this.pageParam.currentPage = parm.currentPage
      this.pageParam.pageSize = parm.pageSize
      this.initVideoNewest()
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  height: 250px;
  width: 100%;
  display: block;
  object-fit: fill;
}

//.hot-icon{
//  position: absolute;
//  margin-top: 18px;
//  margin-left: -4px;
//  width: 36px;
//  height: 24px;
//  background-image: url("../../assets/img/icon/hot.png");
//  background-repeat: no-repeat;
//  background-size: 100%;
//}
.list-box {
  display: inline-flex;

  .box-all {
    //width: 1226px;
    margin-top: 20px;

    .box-item-video {
      background: #4f080e;
      float: left;
      margin-left: 12px;
      list-style: none;
      width: 360px;
      margin-top: 10px;

      //&:hover {
      //  box-shadow: 0px 0px 15px 0px rgba(2, 91, 75, 0.2);
      //}

      a {
        text-decoration: none;
      }

      .el-card__body {
        padding: 0;
      }

      .item-video-img {
        width: 100%;

        img {
          display: block;
          height: 220px;
          width: 100%;
          padding: 5px;
        }
      }

      .item-video-title {
        padding: 0 10px;
        font-size: 14px;
        font-weight: 400;
        color: #f6b95e;
        line-height: 42px;
      }

      .item-video-download {
        margin: 10px auto;
        text-align: center;
      }
    }

    .box-all-text {
      .box-item-text:hover {
        //background-color: #79000f;

        .item-text-title {
          color: #f99500;
        }

        .item-text-desc {
          color: #f99500;
        }
      }

      .box-item-text {
        position: relative;
        height: 271px !important;
        width: calc(100% - 120px);
        text-align: center;
        float: left;
        list-style: none;
        //width: 250px;
       margin: 20px 60px;
        border: 1px solid #f6b95e;
        border-radius: 6px;

        .item-text-title {
          color: rgb(121, 0, 15);
          line-height: 60px;
          font-weight: bold;
          font-size: 18px;
        }

        .item-text-desc {
          color: #a40c1f;
          line-height: 40px;
          height: 100px;
          font-size: 14px;
        }

        .item-text-download {
          // margin-top: 20px;
          position: absolute;
          left: 40%;
          top: 80%;
          text-align: center;
          // width: 200px;
          // height: 30px;
          // background: orange;
          bottom: 0;
        }
      }
    }
  }
}
.kecheng_wk .class_k {
  border-top: 0;
}
.kecheng_wk .row a {
  text-align: center;
  .item-text-desc {
    text-align: left;
    text-indent: 10px;
  }
}
</style>
